const style = document.createElement("style");
style.innerHTML = `.udesly-magnify-glass{left:0;top:0;z-index:99;position:absolute;width:auto;background-color:transparent;background-position:50% 50%;background-repeat:no-repeat;transform:translateZ(0);-webkit-transform:translateZ(0);pointer-events:none}.udesly-magnify-glass .image_wrap{left:0;top:0;z-index:99;position:absolute;overflow:hidden}.udesly-magnify-glass.zp-invisible{display:none}.udesly-magnify-glass.zp-visible{display:block}.udesly-magnify-glass img{display:block;position:absolute;left:0;top:0;max-width:unset;max-height:unset}`;
document.head.appendChild(style);

function magnifyGlass(
  imageSource: HTMLImageElement,
  image: HTMLImageElement,
  zoomplePreviewholder: HTMLElement,
  imageWrapWidth: number,
  imageWrapHeight: number
) {

  const naturalHeight = (imageSource as HTMLImageElement).naturalHeight;
  const naturalWidth = (imageSource as HTMLImageElement).naturalWidth;



  const zoomHeight = naturalHeight / (imageSource as HTMLImageElement).height;

  const zoomWidth = naturalWidth / (imageSource as HTMLImageElement).width;



  (imageSource as HTMLImageElement).addEventListener("mouseenter", e => {

    e.preventDefault();
    image.src = (imageSource as HTMLImageElement).getAttribute("src") || "";


    zoomplePreviewholder.classList.remove("zp-invisible");
    zoomplePreviewholder.classList.add("zp-visible");
    zoomplePreviewholder.setAttribute(
      "style",
      `left:${e.pageX - imageWrapWidth / 2}px;top:${e.pageY -
        imageWrapHeight / 2}px;`
    );

    image.setAttribute(
      "style",
      `height:${naturalHeight}px;width:${naturalWidth}px;`
    );
    let left = String(
      ((e.pageX - (imageSource as HTMLImageElement).offsetLeft) * zoomWidth -
        imageWrapWidth / 2) *
        -1
    );
    let top = String(
      ((e.pageY - (imageSource as HTMLImageElement).offsetTop) * zoomHeight -
        imageWrapHeight / 2) *
        -1
    );

    image.setAttribute("style", `left:${left}px;top:${top}px;`);
  });

  (imageSource as HTMLImageElement).addEventListener("mouseout", e => {
    e.preventDefault();

    zoomplePreviewholder.classList.remove("zp-visible");
    zoomplePreviewholder.classList.add("zp-invisible");
  });

  (imageSource as HTMLImageElement).addEventListener("mousemove" , e => {

    if (!zoomplePreviewholder.classList.contains("zp-visible")) {
      image.src = (imageSource as HTMLImageElement).getAttribute("src") || "";
      zoomplePreviewholder.classList.remove("zp-invisible");
      zoomplePreviewholder.classList.add("zp-visible");
      zoomplePreviewholder.setAttribute(
        "style",
        `height:${imageWrapHeight}px;width:${imageWrapWidth}px;`
      );
      image.setAttribute(
        "style",
        `height:${naturalHeight}px;width:${naturalWidth}px;`
      );
      zoomplePreviewholder.setAttribute(
        "style",
        `left:${e.pageX - imageWrapWidth / 2}px;top:${e.pageY -
          imageWrapHeight / 2}px;`
      );
    }
    e.preventDefault();
    e.stopPropagation();

    zoomplePreviewholder.setAttribute(
      "style",
      `left:${e.pageX - imageWrapWidth / 2}px;top:${e.pageY -
        imageWrapHeight / 2}px;`
    );

    let left = String(
      ((e.pageX - (imageSource as HTMLImageElement).offsetLeft) * zoomWidth -
        imageWrapWidth / 2) *
        -1
    );
    let top = String(
      ((e.pageY - (imageSource as HTMLImageElement).offsetTop) * zoomHeight -
        imageWrapHeight / 2) *
        -1
    );

    image.setAttribute("style", `left:${left}px;top:${top}px;`);
  });


  (imageSource as HTMLImageElement).addEventListener("touchmove" , e => {

    if (!zoomplePreviewholder.classList.contains("zp-visible")) {
      image.src = (imageSource as HTMLImageElement).getAttribute("src") || "";
      zoomplePreviewholder.classList.remove("zp-invisible");
      zoomplePreviewholder.classList.add("zp-visible");
      zoomplePreviewholder.setAttribute(
        "style",
        `height:${imageWrapHeight}px;width:${imageWrapWidth}px;`
      );
      image.setAttribute(
        "style",
        `height:${naturalHeight}px;width:${naturalWidth}px;`
      );
      for(let i = 0; i<e.changedTouches.length;i++){
         zoomplePreviewholder.setAttribute(
        "style",
        `left:${e.changedTouches[i].pageX - imageWrapWidth / 2}px;top:${e.changedTouches[i].pageY -
          imageWrapHeight / 2}px;`
      );
      }

    }
    e.preventDefault();
    e.stopPropagation();

    for(let i =0; i<e.changedTouches.length; i++){
      zoomplePreviewholder.setAttribute(
      "style",
      `left:${e.changedTouches[i].pageX - imageWrapWidth / 2}px;top:${e.changedTouches[i].pageY -
        imageWrapHeight / 2}px;`
    );

    let left = String(
      ((e.changedTouches[i].pageX - (imageSource as HTMLImageElement).offsetLeft) * zoomWidth -
        imageWrapWidth / 2) *
        -1
    );
    let top = String(
      ((e.changedTouches[i].pageY - (imageSource as HTMLImageElement).offsetTop) * zoomHeight -
        imageWrapHeight / 2) *
        -1
    );
    image.setAttribute("style", `left:${left}px;top:${top}px;`);
    }



  });

  (imageSource as HTMLImageElement).addEventListener("touchend", e => {
    e.preventDefault();

    zoomplePreviewholder.classList.remove("zp-visible");
    zoomplePreviewholder.classList.add("zp-invisible");
  });
}

document.querySelectorAll("[udesly-magnify-glass]").forEach(el => {
  const zoomplePreviewholder = document.createElement("div");

  zoomplePreviewholder.classList.add("udesly-magnify-glass", "zp-invisible");

  const imageWrap = el.querySelector(
    '[magnify-glass="magnify-glass"]'
  ) as HTMLElement;

  imageWrap.style.display = "block";
  const imageWrapHeight = imageWrap.clientHeight;
  const imageWrapWidth = imageWrap.clientWidth;

  imageWrap.classList.add("image_wrap");
  const image = document.createElement("img") as HTMLImageElement;

  imageWrap.appendChild(image);

  zoomplePreviewholder.appendChild(imageWrap);
  document.body.appendChild(zoomplePreviewholder);

  el.querySelectorAll('[magnify-glass="image"]').forEach(imageSource => {
    imageSource.removeAttribute('srcset');
    imageSource.removeAttribute('sizes');
    if ((imageSource as HTMLImageElement).complete) {
      magnifyGlass(
        imageSource as HTMLImageElement,
        image,
        zoomplePreviewholder,
        imageWrapWidth,
        imageWrapHeight
      );
    } else {
      (imageSource as HTMLImageElement).onload = () => {
        magnifyGlass(
          imageSource as HTMLImageElement,
          image,
          zoomplePreviewholder,
          imageWrapWidth,
          imageWrapHeight
        );
      };
    }
  });
});
